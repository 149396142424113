<template>
  <div class="app">
    <div class="odds-payout" v-show="betslip_count > 0">
      <div class="total-odds small-text">Total Odds: {{ odds | formatOdds }}</div>
      <div class="possible-payout small-text">Possible Payout: {{ payout |currency }}</div>
    </div>

    <div class="bottom-navigation">
      <a class="nav-item" @click="showMenu">
        <i class="bi bi-list"></i>
        <span class="item-name">Menu</span>
      </a>

      <router-link to="/live" class="nav-item">
        <sup class="counter">{{ liveGames }}</sup>
        <i class="bi bi-play-fill"></i>
        <span class="item-name">Live</span>
      </router-link>

      <div class="major-counter" @click="showBetslip">
        <span>{{ betslip_count }}</span>
      </div>

      <router-link to="/my-bets" class="nav-item">
        <!--        <sup class="counter">{{ activeBets }}</sup>-->
        <i class="bi bi-ticket-fill"></i>
        <span class="item-name">My Bets</span>
      </router-link>

      <router-link v-show="isLoggedIn" to="/profile" class="nav-item">
        <i class="bi bi-person-fill"></i>
        <span class="item-name">Bal {{ bal }}</span>
      </router-link>

      <router-link v-show="!isLoggedIn" to="/login" class="nav-item">
        <i class="bi bi-person-fill"></i>
        <span class="item-name">Login</span>
      </router-link>
    </div>

    <!-- share bet modal -->
    <span id="open-sharebet" class="hidden"></span>

    <span id="betslip-init" class="hidden"></span>

    <!-- Betslipmodal -->
    <div id="betslip-modal" v-bind:class="modalClass" style=" z-index: 99999">

      <div id="status"></div>

      <!-- Modal content -->
      <div style="" v-bind:class="modalContentClass" id="betmobile">

        <section style="background-color: var(--red);border-bottom: solid var(--grey) 1px;" class="betslip-header">
          
          <div style="background-color: var(--red);border-top: solid var(--grey) 1px;" class="row m-0 p-0">
            <div style="background-color: var(--red);" class="col-4 p-0 m-0">
              <div style="background-color: var(--red);" class="d-flex p-2">
                <span class="slip-counter-header slip-text-header text-dark">
                    {{ betslip_count }}
                </span>
                <span style="margin-top: 5px;" class="slip-text-header text-light">
                    Betslip
                </span>
              </div>
            </div>
            <div style="background-color: var(--red);" class="col-8 p-0 m-0">
              <div style="background-color: var(--red);float: right;" class="d-flex p-2">
                <span class="btn btn-sm btn-warning slip-text-header text-dark close-text" id="faso-close">
                  [x]
                </span>
              </div>
              <div style="background-color: var(--red);float: right;" class="d-flex p-2">
                <span class="btn btn-sm btn-warning slip-text-header text-dark" @click="clearBetSlip">
                  Remove All
                </span>
              </div>
            </div>
          </div>
        </section>
        
        <div class="collapse details-collapse py-2 px-4" id="collapseBetmenu" v-show="betslip_count > 0">
          <div class="text-dark">
            <strong>Settings</strong>
          </div>
          <div class="text-dark">
            Enable odd change automatically
          </div>
          <div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="checkbox" id="customRadioInline1" name="customRadioInline1" class="custom-control-input"
                     v-model="accept_odds_changes">
              <label class="custom-control-label text-dark" for="customRadioInline1">Accept Odds Changes</label>
            </div>
            <!--<div class="custom-control custom-radio custom-control-inline">
              <input checked type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input">
              <label class="custom-control-label text-dark" for="customRadioInline2">Disable</label>
            </div>-->
          </div>

        </div>
        <hr class="mt-0 mb-1">

        <div class="faso-modal-body" style="height: 63vh;overflow-y: auto;">

          <Pick v-show="betslip_count > 0" v-for="(bet,index) in betslip.picks" v-bind:key="index"
                v-bind:bet="bet"></Pick>
          <!--<section v-show="betslip_count === 0">

            <div class="form-group">
              <label style="color: black">Do you have a booking code? Enter your booking code below</label>
              <input type="text" class="form-control input-login" placeholder="e.g FBXXX" v-model="booking_code">
            </div>

          </section>-->

        </div>

        <div class="" style="position: fixed;bottom: 0;width: 100%;max-width: 528px;">

          <div v-if="betslip_count !== 0" class="faso-modal-footer d-flex flex-column pb-0" style="padding: 2px 16px;">

            <section class="dets px-0 py-2 " style="padding: 10px;border-top: solid black var(--grey);border-top: solid black 1px;">
              <div style="border-top: solid black var(--grey);" class="row m-0 p-0">
                <div class="col-6 m-0 p-0">
                  <div class="dets-title text-dark">
                    Total Odds
                  </div>
                  <div class="text-dark small-text">
                    Min. 1.075
                  </div>
                </div>
                <div class="col-6 m-0 p-0">
                  <div class="dets-value text-dark text-right">
                    {{ betslip.odds | formatOdds }}
                  </div>
                </div>
              </div>
              <hr class="mt-1 mb-0">
              <div class="mb-1 " v-if="isLoggedIn && (profile.b2) > 0">
                <div class="frebet-title text-dark bg-light px-1">
                  Your Bonus
                </div>
                <div class="d-flex justify-content-between bg-light p-1">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                           :disabled="betslip.odds < 3.5" :checked="can_get_bonus" v-on:click="bonusUpdate">
                    <label class="form-check-label text-dark" for="flexRadioDefault2">
                      Use {{ (profile.b2) | currency }} Bonus
                    </label>
                  </div>
                  <div v-if="betslip.odds > 3.4" class="text-dark font-weight-bold font-size-11">
                    Available
                  </div>
                  <div v-if="betslip.odds < 3.5 " class="font-size-11" style="color: #707070">
                    Unavailable
                  </div>
                </div>

              </div>
              <div class="row m-0 p-0">
                <div style="margin-bottom: 14px;" class="col-12 p-0">
                  <div class="dets-title text-dark">
                    Stake amount
                  </div>
                  <div class="text-dark small-text line-height-1">
                    Min. Ksh 20
                  </div>
                  <div class="text-dark small-text line-height-1">
                    Max. Ksh 100,000
                  </div>
                </div>
                <div class="col-12 m-0 p-0 slip-amount-bg">
                  <div class="mb-1 d-flex align-items-center">
                    <!-- <div class="input-group-prepend" @click="decrementStake">
                      <span style="background-color: var(--yellow);color: var(--grey);" class="input-group-text"><i class="bi bi-minus"></i>-</span>
                    </div> -->
                    <div class="input-group-append" @click="decrementStake">
                      <span style="background-color: var(--yellow);color: var(--grey);" class="input-group-text">-</span>
                    </div>
                    <input style="border: solid black 1px; flex: 1 1 auto; margin: 0 4px;" type="number" id="stake" @keyup="checkLetters" class="form-control slip-amount"
                           placeholder="100" aria-label="Amount" v-model="stake" :disabled="can_get_bonus">
                    <div class="input-group-append" @click="incrementStake">
                      <span style="background-color: var(--yellow);color: var(--grey);" class="input-group-text">+</span>
                    </div>
                  </div>                  

                </div>
              </div>
              <hr class="mt-1 mb-1">
              <div class="row m-0 p-0">
                <div class="col-6 m-0 p-0">
                  <div class="dets-title text-dark">
                    Final Payout
                  </div>
                  <div class="text-dark small-text hover-pointer" data-toggle="collapse" href="#collapseDetails"
                       role="button" aria-expanded="false" aria-controls="collapseDetails">
                    <i class="bi bi-info-circle"></i> Details
                  </div>
                </div>
                <div class="col-6 m-0 p-0">
                  <div class="dets-value text-dark text-right">
                    {{ betslip.payout | currency }}
                  </div>
                </div>
              </div>
              <hr class="mt-1 mb-1">
              <!--  Add accordion for details  -->
              <div class="collapse details-collapse px-2 " id="collapseDetails">
                <div class="d-none justify-content-between">
                  <div class="dets-sub small-text text-dark">
                    Excise tax
                  </div>
                  <div class="dets-val small-text text-dark">
                    -Ksh 8.00
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="dets-sub small-text text-dark">
                    Stake tax
                  </div>
                  <div class="dets-val small-text text-dark">
                    {{ stake - ((stake * 100) / (100 + 7.5)) | currency }}
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="dets-sub small-text text-dark">
                    Stake After tax
                  </div>
                  <div class="dets-val small-text text-dark">
                    {{ ((stake * 100) / (100 + 7.5)) | currency }}
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="dets-sub small-text text-dark">
                    WH tax
                  </div>
                  <div class="dets-val small-text text-dark">
                    {{ betslip.tax | currency }}
                  </div>
                </div>

              </div>

              <div class="py-2 text-danger text-center text-underline d-none">
                <u v-if="has_suspended_picks" @click="removeSuspendedPicks">(REMOVE EXPIRED GAMES)</u>
              </div>
              <section class="shareSlipSection collapse " id="collapseShareSlip">

                <div class="text-dark text-center matches-title mb-2">
                  Share Betslip
                </div>

                <div class="social-icons d-flex justify-content-around px-2 text-center mb-2">
                  <a href="https://www.facebook.com/PonyokaKE/"
                     target="_blank" title="Tweet"
                     onclick="window.open('https://www.facebook.com/PonyokaKE/' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
                    <img src="/img/facebook.png"><br>
                    <span class="text-dark small-text"> Facebook</span>
                  </a>

                  <a href="https://instagram.com/Ponyoka?utm_medium=copy_link&t="
                     title="Share on Facebook"
                     onclick="window.open('https://instagram.com/Ponyoka?utm_medium=copy_link' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&t=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
                     target="_blank">
                    <img src="/img/instagram.png"> <br>
                    <span class="text-dark small-text"> Instagram</span>
                  </a>

                  <a data-action="share/whatsapp/share" target="_blank"
                     onclick="window.open('whatsapp://send?text=' + document.getElementById('share-bet-msg').textContent); return false;">
                    <img src="/img/whatsapp.png"><br>
                    <span class="text-dark small-text"> Whatsapp</span>
                  </a>

                  <a href="https://twitter.com/PonyokaKE?s=11"
                     target="_blank" title="Tweet"
                     onclick="window.open('https://twitter.com/PonyokaKE?s=11text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
                    <img src="/img/twitter.png"><br>
                    <span class="text-dark small-text"> Twitter</span>
                  </a>

                  <a data-action="share/telegram/share" target="_blank"
                     onclick="window.open('https://telegram.me/share/url?url=' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
                    <img src="/img/telegram.png"><br>
                    <span class="text-dark small-text"> Telegram</span>
                  </a>

                </div>

                <div class="row p-0 m-0 px-2 mb-2">
                  <div class="col-7 px-1">
                    <div id="share-bet-link" class="share-betslip-btn" style="color: #FFFFFF">
                      https://Ponyoka.co.ke/share/{{ code }}
                    </div>
                    <div class="d-none" id="share-bet-code">{{ code }}</div>
                    <div class="d-none" id="share-bet-odds">{{ odds }}</div>
                    <div class="d-none" id="share-bet-msg">{{ msg }}</div>
                  </div>
                  <div class="col-5 px-1">
                    <button class="share-btn" @click="copyShareBetLink">{{ copyText }}</button>
                  </div>
                </div>

                <div class="text-center slip-ttxt py-3 d-none">
                  <div class="text-dark mb-3">You can also download a copy of your betslip in pdf</div>
                  <div>
                    <a href="" class="login-button py-2 px-4 ">Download betslip</a>
                  </div>
                </div>

                <hr class="m-1">

                <div class="text-center slip-ttxt py-3 d-none">
                  <div class="text-dark mb-3">Want to print out the betslip?</div>
                  <div>
                    <a href="" class="login-button py-2 px-4 ">Print betslip</a>
                  </div>
                </div>

              </section>
              <div class="text-center d-flex justify-content-center align-items-center" style="gap: 10px;">
                <a style="border-radius: 5px; width: 20%;" id="open-sharebet" href="#collapseShareSlip" class="login-button py-2 px-4" 
                   data-toggle="collapse" data-target="#collapseShareSlip" aria-expanded="false" aria-controls="collapseShareSlip" 
                   @click="shareBet">Share</a>
                <a style="background-color: var(--yellow); border-radius: 5px; width: 80%;" @click="placeBet" 
                   class="join-button py-2 form-control" v-bind:class="loading">
                  <span style="color: var(--grey);">Place Bet</span>
                </a>
              </div>
              
            </section>
          </div>
        </div>

      </div>

    </div>
    <!-- Betslipmodal -->
  </div>

</template>

<script>

import axios from "@/services/api";
import Pick from "./Pick";

export default {
  name: "bottom-navigation",
  props: {
    page: {
      required: false
    },
  },
  data: function () {
    return {
      stake: 50,
      loading: '',
      bestlip_visible: false,
      code: '',
      msg: '',
      share_odds: '',
      copyText: 'Copy Link',
      copyBookingCode: 'Click to copy',
      myProfile: this.getProfile(),
      accept_odds_changes: true,
      previous_odds: 0,
      odds_changed: false,
      seen: false,
      withholding_tax: 0,
      booking_code: '',
      can_get_bonus: false
    }
  },
  methods: {
    checkLetters: function () {
      // var testString = this.stake;

      // if(parseInt(testString)){
      //   /* do something if letters are found in your string */

      // } else {
      //   this.stake = 0;

      // }
    },
    loadBookingCode: function () {

      if (this.booking_code.trim().length > 4) {

        if (this.currentRouteName !== "share") {

          this.$router.push({name: 'share', params: {code: this.booking_code.trim()}});

        } else {

          this.EventBus.$emit('share:code', this.booking_code.trim());
        }

      } else {

        this.setError("invalid code", "Please enter a valid booking code to proceed");

      }

    },
    shareBetMessage: function () {
      //var code = this.code;
      var odds = this.share_odds;
      var toWin = this.formatCurrency(parseFloat(odds) * this.stake);
      this.msg = this.msg.replace('{possible_win}', toWin);
      this.msg = this.msg.replace('{line}', '');
      this.msg = this.msg.replace('{line}', '');
      this.msg = this.msg.replace('{line}', '');
      this.msg = this.msg.replace('{line}', '');
      this.msg = this.msg.replace('{stake}', this.stake);

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    },

    bonusUpdate: function () {
      // console.log(this.getProfile())
      if (this.can_get_bonus) {
        this.can_get_bonus = false;
      } else {
        this.can_get_bonus = true;
        this.stake = this.myProfile.b2
      }
    },

    getSportAlt: function (sport_id) {

      switch (parseInt(sport_id)) {

        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }

      return ""
    },
    show: function () {

      this.jQuery('#betslip-modal').modal('show');
      //var modal = document.getElementById("betslip-modal");
      //modal.style.display = "block";
      this.bestlip_visible = true;
    },
    tax: function () {
      // const wage_tax = 7.5;
      // const winning_tax = 20/100;
      // let stake = this.stake;
      // let odd = this.betslip.odds;

      // let stake_after_tax = (stake * 100)/ (100+wage_tax);
      // let stake_tax = stake - stake_after_tax;

      // let winning = odd * stake_after_tax;
      // let w_tax = (winning - stake_after_tax) * winning_tax;

      // this.withholding_tax = w_tax;
      // this.stake_tax = stake_tax;

    },

    placeBet: function () {

      this.reset();
      var p = this.getProfile();

      if (!p) {

        this.setError("Login", "Please login to proceed");
        this.setValue("placeBet", 1);
        this.$router.push({name: 'login', params: {}});
        return;
      }

      var bet_amount = this.getValue("stake");
      var booking_code = this.getValue("booking_code");
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      if (bet_amount < 1) {

        this.setError("Invalid Bet Amount", "Please enter bet amount greater of 1Ksh or more ");
        return;
      }

      // if(bet_amount > p.b1 && !this.can_get_bonus){
      //   this.setError("Insufficient Funds", "Please top up your account ");
      //   this.$router.push({ name: 'deposit', params: { } });
      //   return;
      // }

      var betslipData = this.betslip;

      if (betslipData.total === 0) {

        this.setError("Invalid Bets", "Please Select atleast one outcome to continue");
        return;
      }

      if (!this.accept_odds_changes && this.odds_changed) {

        this.setError("Odds Changed", "Accept Odds Changes to Proceed");
        return;

      }

      var bets = [];

      this.jQuery.each(betslipData.picks, function (k, v) {

        bets.push({
          odd_id: v.odd_id,
          match_id: v.match_id,
          producer_id: v.producer_id,
        });

      });

      var data = {
        stake: bet_amount,
        stake_type: this.can_get_bonus ? "bonus" : "cash",
        bets: bets,
        code: booking_code,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
        source: this.isMobile() ? 2 : 1
      };

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_BET.replace("{profile_id}", p.d);

      axios.post(path, JSON.stringify(data), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var profile = res.data.message.profile;
            var message = res.data.message.message;
            vm.setProfile(profile);
            vm.hideBetslip();
            vm.clearBetSlip();
            vm.setSuccess("Success", message);
            vm.removeObject('booking_code');
            vm.removeObject('utm_source');
            vm.goHome();

          })
          .catch(err => {

            //console.log(JSON.stringify(err,undefined,4));
            vm.hideBetslip();
            vm.loading = '';

            if (err.response) {

              var message = "";

              if (parseInt(err.response.status) === 428) {

                message = err.response.data.message.message;
                //var amount = err.response.data.message.amount;

                /*
                vm.EventBus.$emit('deposit:popup',{
                  amount: amount,
                  message:message,
                });
                */

                vm.setError("Failed", message)
                return;
              }

              if (parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message = err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })
    },
    oddStatus: function () {

      var picks = this.betslip.picks;

      if (picks === undefined || picks.length === 0) {

        return;

      }

      var bets = [];

      this.jQuery.each(picks, function (k, v) {

        bets.push(v.odd_id + ":" + v.producer_id);

      });

      var data = {
        odd_id: bets.join(',')
      };
      //console.log("STATUS ODD--->", JSON.stringify(data));

      if (bets.length === 0) {

        return;

      }

      var vm = this;

      var path = process.env.VUE_APP_URL_ODD_STATUS;

      axios.post(path, JSON.stringify(data))
          .then(res => {

            var bets = res.data.message;

            vm.jQuery.each(bets, function (k, v) {

              var oddID = v.id;
              var odds = v.odds;
              var previous_odds = v.previous_odds;
              var status = v.status;
              var active = v.active;
              var producer_status = v.producer_status;

              // get original odds
              // check odds changes
              vm.jQuery.each(picks, function (k1, v1) {

                if (parseInt(v1.odd_id) === parseInt(oddID)) {

                  var oddChangeText = "";
                  odds = vm.formatOdds(odds);

                  if (odds !== vm.formatOdds(v1.odd)) {

                    // odds changed
                    if (odds > v1.odd) {

                      var direction = 1

                    } else {

                      direction = 0

                    }

                    var directionTxt = parseInt(direction) === 1 ? 'odds has gone up ' : 'odds has gone down ';
                    oddChangeText = directionTxt + ' from ' + v1.odd + ' to ' + odds;

                  }

                  v1.status = status;
                  v1.active = active;
                  v1.odds = odds;
                  v1.odd = odds;
                  v1.previous_odds = previous_odds;
                  v1.producer_status = producer_status;
                  v1.odds_change_text = oddChangeText;
                  picks[k1] = v1;
                }
              })

            });

            var pk = [];

            vm.jQuery.each(picks, function (k1, v1) {

              pk.push(v1);
            })

            vm.saveObject("bslip", pk);
            vm.autoRefreshUI(vm.$vnode.tag);

          })
          .catch(err => {

            //console.log("ERROR "+JSON.stringify(err,undefined,2));

            if (err.response) {

              vm.setError("Failed", err.response.data.message)

            } else if (err.request) {

              //vm.setError("Network Error", "Check your network connection and try again")
              //console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Network Error", "Check your network connection and try again")
              //console.log(JSON.stringify(err));

            }
          })
    },
    getOddStatus: function (odd) {

      if (odd.active === 0 || odd.producer_status === 0) {

        return '<div class="deactivated-odds">Outcome suspended</div>';

      }

      if (parseInt(odd.status) === 0 || parseInt(odd.status) === -1) {

        return '<div class="deactivated-odds">Outcome Deactivated</div>';

      }

      if (odd.odds_change_text && odd.odds_change_text.length > 0) {

        return '<div class="odds-change" style="">' + odd.odds_change_text + '</div>';

      }

    },
    initSharebetModal: function () {

      var modal = document.getElementById("sharebet-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("open-sharebet");

      // Get the <span> element that closes the modal
      //var span = document.getElementsByClassName("sharebet-close")[0];

      // When the user clicks on the button, open the modal
      btn.onclick = function () {
        // modal.style.display = "block";
      }

      // When the user clicks on <span> (x), close the modal
      /*
      span.onclick = function() {
        modal.style.display = "none";
      }
      */

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("#sharebet-modal")) {

          modal.style.display = "none";
        }

      });

    },
    initBetslipModal: function () {

      var vm = this;

      // Get the modal
      var modal = document.getElementById("betslip-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("betslip-init");

      // Get the <span> element that closes the modal
      var span = document.getElementById("faso-close");

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        vm.previous_odds = vm.betslip.odds;
        vm.oddStatus();
        vm.tax();
      }

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      }

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {

          modal.style.display = "none";
        }
      }

      document.addEventListener("click", e => {

        if (e.target === document.querySelector("#betslip-modal")) {

          modal.style.display = "none";
        }

      });

      document.addEventListener("click", e => {

        if (e.target === document.querySelector("#betslip-modal")) {

          modal.style.display = "none";
        }

      });


    },
    incrementStake: function () {
      console.log(this.stake)
      if (!this.can_get_bonus) {
        this.stake = this.stake + 50;
      }

    },
    decrementStake: function () {
      if ((this.stake - 50) > 49 && !this.can_get_bonus) {
        this.stake = this.stake - 50;
      }
    },
    showBetslip: function () {
      // if(this.$route.path == '/login'){
      //   this.$router.push({name: 'home', params: {}});
      // }
      if (document.getElementById("betslip-init") !== null) {
        document.getElementById("betslip-init").click();
        this.$store.dispatch("setPlaceBet", 0);
      }

    },
    hideBetslip: function () {
      document.getElementById("faso-close").click();
    },
    shareBet: function () {

      var p = this.getProfile();

      var betslipData = this.betslip;

      if (betslipData.total === 0) {

        this.setError("Invalid Bets", "Please Select atleast one outcome to continue");
        return;
      }

      var bets = [];

      this.jQuery.each(betslipData.picks, function (k, v) {

        bets.push({
          market_id: v.market_id,
          match_id: v.match_id,
          outcome_id: v.outcome_id,
          specifier: v.specifier,
        });

      });

      var data = {
        profile_id: p.id,
        bets: bets,
        bet_type: 1
      };

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_SHARE;

      axios.post(path, JSON.stringify(data))
          .then(res => {

            vm.loading = '';
            vm.code = res.data.message.code;
            vm.msg = res.data.message.message;

            console.log("CODE-->" + vm.code);
            vm.share_odds = betslipData.odds;
            vm.shareBetMessage();
            console.log(vm.msg);
            vm.copyText = 'Copy';
            vm.copyBookingCode = 'Click to Copy'
            document.getElementById("open-sharebet").click();

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }
          })
    },
    copyShareBetLink: function () {

      var link = "https://Ponyoka.co.ke/share/" + this.code;
      this.copyToClipboard(link);
      this.copyText = 'Copied';

    },
    copyCode: function () {

      var link = "book#" + this.code + "#stakeAmount";
      this.copyToClipboard(link);
      this.copyBookingCode = 'Booking Code Copied';

    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    bal: function () {

      return this.formatCurrency(this.profile.b1);

    },

    iconSize: function () {

      return 28;
    },
    profile: function () {

      return this.myProfile;

    },
    liveGames: function () {

      return this.$store.state.live_match_counter

    },
    homePageIcon: function () {

      if (this.page === 'home') {

        return this.getURL("/assets/images/home_icon_selected.svg");

      }

      return this.getURL("/assets/images/home_icon.svg");

    },
    homePageFontColor: function () {

      if (this.page === 'home') {

        return "yellow-txt";
      }

      return "";

    },
    livePageFontColor: function () {

      if (this.page === 'live') {

        return "yellow-txt";
      }

      return "";

    },
    historyPageFontColor: function () {

      if (this.page === 'history') {

        return "yellow-txt";
      }

      return "";

    },
    betslip_count: function () {

      if (!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },

    activeBets: function () {

      var p = this.getProfile();
      if (!p) {

        return 0;

      }

      return p.b

    },
    odds: function () {

      return this.betslip.odds
      //return this.$store.state.betslip.odds

    },
    payout: function () {

      return this.betslip.payout

    },
    betslip: function () {

      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.betslip
    },
    has_suspended_picks: function () {

      return this.$store.state.has_suspended_picks

    },

    isLoggedIn: function () {

      var p = this.getProfile();
      if (!p) {
        return false;
      }

      return true;
    },
    modalClass: function () {

      if (parseInt(this.betslip_count) === 0) {

        return "faso-modal-booking-code"
      } else {

        return "faso-modal"

      }
    },
    modalContentClass: function () {

      if (parseInt(this.betslip_count) === 0) {

        return "faso-modal-content-booking-code"

      } else {

        return "faso-modal-content"
      }

    }

  },
  filters: {

    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'Ksh',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
  watch: {

    stake: function (newValue) {

      if (parseInt(newValue) < 1) {

        this.stake = 1
        newValue = 1

      }

      // update stake
      this.setValue("stake", newValue);

      // recalculate winnings
      this.autoRefreshUI(this.$vnode.tag);

    }
  },
  components: {
    Pick
  },
  mounted: function () {

    // reset stake
    this.setValue("stake", 50);
    this.initBetslipModal();
    this.initSharebetModal();
    this.previous_odds = this.betslip.odds;
    console.log(this.betslip);

    this.$store.dispatch("getLiveMatchCounter");
    var vm = this;
    if (this.$store.state.placeBet == 1) {
      vm.showBetslip();
      this.$store.dispatch("setPlaceBet", 0);
    }
    console.log("VM Store Place Bet:" + this.$store.state.placeBet)
    this.EventBus.$on('event:betslip:show', function () {

      vm.showBetslip();

      //'odds:changed'

    });

    this.EventBus.$on('event:betslip:hide', function () {

      vm.hideBetslip();

    });

    this.EventBus.$on('event:betslip:placeBet', function () {

      vm.placeBet(true);

    });

    this.EventBus.$on('profile:balance', function (payload) {

      vm.myProfile = payload;

    });

    this.EventBus.$on('share:bet', function (payload) {

      vm.code = payload.code;
      vm.msg = payload.message;
      vm.share_odds = payload.odds;
      vm.shareBetMessage();
      document.getElementById("open-sharebet").click();

    });

    this.EventBus.$on('odds:changed', function (payload) {

      vm.odds_changed = true;
      vm.updateOdd(payload.odd_id, payload.odds, payload.previous_odds, payload.active, payload.status);

    });

    /*
    setInterval(function () {

      var betslipData = vm.betslip;

      if (betslipData.total > 0) {

        vm.jQuery.each(betslipData.picks, function (k, v) {

          vm.pollBetSlip(v.producer_id,v.sport_id,v.match_id,v.market_id)

        });

      }

    },1000);

     */

  }
}
</script>


<style scoped>
.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
}

.odds-payout {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--secondary);
  border-bottom: 1px solid #ccc;
  height: 1em;
  max-width: 528px;
  margin: 0 auto;
}

.total-odds,
.possible-payout {
  font-size: 14px;
  color: #000000;
}

.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--red);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 50px;
  transition: transform 0.3s ease-in-out;
  max-width: 528px;
  margin: 0 auto;
}

.bottom-navigation.sticky {
  transform: translateY(0);
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #fff;
  line-height: 1;
}

.item-name {
  margin-top: 5px;
}

.nav-item > i {
  font-size: 20px;
  margin-right: 10px;
}

.nav-item > span {
  font-size: 14px;
}

.counter {
  font-size: 10px;
  position: relative;
  top: 5px !important;
  margin-left: 17px;
  color: var(--secondary);
}

.major-counter {
  width: 1.6em;
  height: 1.6em;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  margin: 0 15px;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>